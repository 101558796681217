<script>
import {
  Carousel,
  Slide
} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
// import Footer from "@/components/footer";
import referralApi from "@/apis/referralApi";
import storage from '../../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import date from "../../date";
// import Navbar2 from "../../components/navbar2.vue";
import blogApi from '../../apis/blogApi';
// import Navbar from "../../components/navbar.vue";
// import Navbar3 from "../../components/navbar3.vue";
import Navbar from "@/views/new-view/components/navbar";
import Footer from "@/views/new-view/components/footerBar";

/**
 * Index-1
 */
export default {
  metaInfo: {
    title: 'IncCrypto: The Best Cloud Mining Platform and Service Provider for Cloud Mining Technology Services',
    link: [
      { rel: 'canonical', href: 'https://inccrypto.com/what-is-mining' }
    ]
  },
  data() {
    return {
      data: [],
      login: false
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    // Navbar2,
    Navbar,
    // Navbar3
  },
  created() {
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true;
    } else {

    }
  }
};
</script>

<template>
  <div>
    <Navbar v-if="!login"/>
    <Navbar v-else/>


    <!-- 顶部 -->
    <section class="bg-half-170 d-table w-100 pb-0" style="padding-top: 100px;">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">What Is Cloud Mining</h4>
            <!--            <p class="text-muted mx-auto mb-0">-->
            <!--              If you could not find an answer to your questions,please contant our customer support-->
            <!--            </p>-->
          </div>
        </div>
      </div>
    </section>
    <section class="section pt-0" style="margin: 0 10px;">
      <div class="container" style="border-radius: 10px;">
        <h3>What is Mining?</h3>
        <br/>
        <p>Over the past several years, cryptocurrencies like Bitcoin have been quietly growing in popularity, with an ever-larger number of people buying and selling them. Now that Bitcoin has hit the mainstream and become a worldwide phenomenon, more people than ever are looking to get into the cryptocurrency game.</p>
        <br/>
        <p>However, the production of cryptocurrencies isn't anything like that of regular money. There's no central authority that issues new notes; instead, bitcoins ( or any of the other so-called 'alt-coins') are generated through a process known as 'mining'. So what is cryptocurrency mining, and how does it work?</p>
        <br/>
        <p>Cryptocurrency mining and the blockchain</p>
        <br/>
        <p>Before getting to grips with the process of cryptocurrency mining, we need to explain what blockchain is and how that works. Blockchain is a technology that supports almost every cryptocurrency. It is a public ledger (decentralised register) of every transaction that has been carried out in that cryptocurrency.</p>
        <br/>
        <p>These transactions are assembled into what are called "blocks". These are the verified to ensure they are legitimate by cryptocurrency miners. This checks if the same coin hasn't been expended again before the transaction has cleared, and that the input and output expenses tally. Then the next sequential transaction block is connected to it. This is how cryptocurrencies are created and how new cryptocoins are made.</p>
        <br/>
        <p>Mining new blocks</p>
        <br/>
        <p>As there is no central authority or central bank, there has to be a way of gathering every transaction carried out with a cryptocurrency in order to create a new block. Network nodes that carry out this task called dubbed 'miners'. Every time a slew of transactions is amassed into a block, this is appended to the blockchain. Whoever appends the block gets rewarded with some of that cryptocurrency.</p>
        <br/>
        <p>To prevent the devaluation of the currency by miners building lots of blocks, the task is made harder to conduct. This is achieved by making miners solve complicated mathematical problems called proof of work'.</p>
        <br/>
        <p>Calculating hashes</p>
        <br/>
        <p>In order to successfully create a block, it must be accompanied by a cryptographic hash that fulfills certain requirements. The only feasible way to arrive at a hash matching the correct criteria is to simply calculate as many as possible and wait until you get a matching hash. When the right hash is found, a new block is formed and the miner that found it is awarded with units of cryptocurrency.</p>
        <br/>
        <p>Think of it like one of those competitions where you have to guess the weight of the cake - only you get unlimited guesses, and the first one to submit a correct answer wins. Whoever can make guesses at the fastest rate has a higher chance of winning.</p>
        <br/>
        <p>Cryptocurrency mining limits</p>
        <br/>
        <p>In practice, this means that miners are competing against each other to calculate as many hashes as possible, in the hopes of getting to be the first one to hit the correct one, form a block and get their cryptocurrency payout.</p>
        <br/>
        <p>However, the difficulty of calculating the hashes also scales - every new block of bitcoins becomes harder to mine. In theory, this ensures that the rate at which new blocks are created remains steady. Many cryptocurrencies also have a finite limit on the amount of units that can ever be generated. For example, there will only ever be 21 million Bitcoins in the world. After that, mining a new block will not generate any bitcoins at all.</p>
        <br/>
        <p>Cryptocurrency mining requirements</p>
        <br/>
        <p>Although you were once able to mine your own cryptocurrencies using a standard PC, this isn't viable any longer; the quality and quantity of hardware you need to mine effectively increases in line with the volume of people mining. That's seen requirements leap - from a reasonably-powerful processor, to a high-end GPU, to several GPUs working in conjunction, to -now - ASIC chips specifically configured for cryptomining.</p>
        <br/>
        <p>Nowadays you will have to spend upwards of $1,000 on the appropriate hardware to mine most modern cryptocurrencies with any success. The energy consumption, meanwhile, is substantial - and you'll need to keep an eye on these rising costs while running your machine 24/7. Most miners will spend the overwhelming majority of their income from mining on paying to maintain and run the equipment.</p>
        <br/>
        <p>As the Bitcoin hype is more or less fully nestled in the wider public consciousness, organisations have invested increasingly considerable sums into it, effectively industrialising cryptocurrency mining. Large warehouses packed to the brim with floor-to-ceiling racks of expensive graphics cards, working towards the sole aim of mining new units of Bitcoin, Ether, Zcash, and so on, have become the new normal.</p>
        <br/>

        <strong style="font-size:22px;">
          What is cloud mining?
        </strong><br/><br>
        <p>Cloud Mining is the process of bitcoin mining utilizing a remote datacenter with shared processing power. This type of cloud mining enables users to mine bitcoins or alternative cryptocurrencies without managing the hardware. The mining rigs are housed and maintained in a facility owned by mining company and the customer simply needs to register and purchase mining contracts. </p>
        <br/>

        <strong style="font-size:22px;">
          What are Cloud Mining Advantages?
        </strong><br/><br>
        <p>No excess heat to deal with</p>
        <p>No constantly noise of fan</p>
        <p>Low electricity costs</p>
        <p>No need space</p>
        <p>No requires hardware/software/technical experience.</p>
        <p>No Pre-ordered mining hardware that may not be delivered on time</p>
        <p>Instant connection</p>
        <p>24/7 uptime</p>
        <br/>


<!--        <strong style="font-size:22px;">-->
<!--          What are Cloud Mining Disadvantages?-->
<!--        </strong><br/><br/>-->
<!--        <p>No fun! If you like building your own mining systems.</p>-->
<!--        <p>Lower profits – Cloud mining services will have expenses</p>-->
<!--        <p>Lack of possession of the mining hardware</p>-->


      </div>
    </section>



    <Footer/>
    <!-- Footer End -->
<!--    <Switcher/>-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
       v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
